<template>
  <div class="page-main">首页</div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
